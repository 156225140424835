// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.0-rc-10278(56f32968ad)-C28/05/2024-12:38:29-B28/05/2024-13:09:39' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.0-rc-10278(56f32968ad)-C28/05/2024-12:38:29-B28/05/2024-13:09:39",
  branch: "master",
  latestTag: "6.0-rc",
  revCount: "10278",
  shortHash: "56f32968ad",
  longHash: "56f32968ad71655d2b310d6970ce373c0a1f3587",
  dateCommit: "28/05/2024-12:38:29",
  dateBuild: "28/05/2024-13:09:39",
  buildType: "Ansible",
  } ;
